import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import pdfIcon from '../images/pdf.jpg'
import cadIcon from '../images/cad.jpg'
import revitIcon from '../images/revit.jpg'
import specIcon from '../images/specIcon.jpg'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import Picture from '../components/Picture';

const DownloadCenter = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const pageData = data.prismicNewDownloadCenter.data

	/**
	*	Form to fill to download a file
	*/
	const popupEl = useRef(null)
	const [popupIsOpen, setPopupIsOpen] = useState(false)

	const handlePopupClose = () => {
		setPopupIsOpen(false)
	}

	const firstUrlRef= useRef(null);

	// Click on download
	const handleDownloadClick = e => {
		const filename = e.target.closest('[data-filename]').getAttribute('data-filename')
		const fileurl = e.target.closest('[data-fileurl]').getAttribute('data-fileurl')
		const userInfosClone = {...userInfos}
		userInfosClone.filename = filename
		userInfosClone.fileurl = fileurl
		if (!isFormSubmitted) {
			setUserInfos(userInfosClone)
			setShowSuccessMessage(false)
			setShowErrorMessage(false)
			setIsSubmissionLoading(false)
			setPopupIsOpen(true)
			firstUrlRef.current = fileurl;
		} else {
			window.open(fileurl, '_blank').focus()
		}
		
	}

	// Form
	const userInfosDefault = {
		fullname: '',
		phone: '',
		email: '',
		hdyhau: '',
		// company: '',
		// city: '',
		filename: '',
		fileurl: ''
	}
	const [userInfos, setUserInfos] = useState(userInfosDefault)
	const [showSuccessMessage, setShowSuccessMessage] = useState(null)
	const [showErrorMessage, setShowErrorMessage] = useState(null)
	const [isSubmissionLoading, setIsSubmissionLoading] = useState(false)
	const [isFormSubmitted, setIsFormSubmitted] = useState(false)
	const formEl = useRef(null)
	const [verified, setVerified] = useState(false);
	
	//Need to find better way to close off polling after verified
	const initiateWebHook = () => {
		const userTriggeredOpen = () => {
			const newWindow = window.open(firstUrlRef.current, '_blank');
			if (newWindow) {
				newWindow.focus();
			} else {
				alert("Failed to open window. It might have been blocked by a popup blocker.");
			}
			setUserInfos(userInfosDefault)
			setIsSubmissionLoading(false)
			setShowSuccessMessage(true)
			setIsFormSubmitted(true)
			setTimeout(() => {
				setPopupIsOpen(false)
			}, 1000);
		}
		userTriggeredOpen();
		setVerified(true);
	}

	useEffect(() => {
	 if (!verified) {
	  const fetchUpdates = async () => {
		try {
		  const response = await fetch('/.netlify/functions/mondayWebhookEndpoint');
		  const data = await response.json();
		  if(data.message === 'Webhook received'){
		  initiateWebHook();
		  } else {
		  }
		} catch (error) {
		}
	  };
  
	  fetchUpdates(); // Fetch immediately
	  const intervalId = setInterval(fetchUpdates, 1000); // Poll every 1 second
  
	  return () => clearInterval(intervalId); // Cleanup on unmount
	}
	}, [verified]);

	const pageTitle = pageData.title[0].text
	const pageSubheading = pageData.subheading[0].text
	const {files, product_group_list, product_select_list} = pageData;
	let productGroupSections = {};
	for (let group of product_group_list) {
		productGroupSections[group.name] = {
			title: group.name, 
			tagline: group.tagline,
			products: []
		}
	}
	for (let product of product_select_list) {		
		let productFiles = [];

		for (let file of files) {
			if ((file.product) === product.product_name) {
				productFiles.push(file);
			}
		}
		productGroupSections[product.product_group].products.push({...product, files: productFiles})
	}

	const productList = Object.keys(productGroupSections).map(key => {
		const productSections = productGroupSections[key];

		const productSection = productSections.products.map(product => { 
			
			const filesSection = product.files.map(file => {
				let srcIcon;
				switch(file.type) {
					case "pdf":
						srcIcon = pdfIcon;
						break;
					case "revit":
						srcIcon = revitIcon;
						break;
					case "cad":
						srcIcon = cadIcon;
						break;
					case "spec":
						srcIcon = specIcon;
						break;
					default:
						srcIcon = pdfIcon;
						break;
				}
				const splitUrl = file.file.url.split("https://");
				const strippedUrl = splitUrl.length > 1 ? splitUrl[1] : splitUrl[0];
				const fileUrl = "https://" + strippedUrl;
				return <div onClick={handleDownloadClick} data-filename={file.name[0].text} data-fileurl={fileUrl}>
					<img
						className="DownloadCenter__FileIcon"
						src={srcIcon}
						alt="File Icon"
					/>
				</div>
			})
			
			return <section>
			<div className='DownloadCenter__horizontalline'></div>
			<div className='DownloadCenter__CategoryItem'>
				<span className='DownloadCenter__CategoryItem__Image'>
					<Picture src={product.thumbnail.url} noDownload={true}/>
				</span>
				<span className='DownloadCenter__CategoryItem__CenterText'>
					<div className='DownloadCenter_File_Name'>
						{product.product_name.includes(" (") ? product.product_name.split(" (")[0] : product.product_name}
					</div>
					<div className='DownloadCenter_File_Details'>
						{ product.base_product !== null && <div><span>Product:</span><span>{product.base_product}</span></div>}
						{ product.orientation !== null && <div><span>Orientation:</span><span>{product.orientation}</span></div>}
						{ product.system !== null && <div><span>System:</span><span>{product.system}</span></div>}	

					</div>
				</span>
				<span className='DownloadCenter__CategoryItem__RightButtons' data-filename="ALPHATON+M2.pdf" data-fileurl='https://shildan.cdn.prismic.io/shildan/0ff201e8-ba26-4034-9de5-070e6557012b_ALPHATON+M2.pdf'>
					<div className="DownloadCenter__DownloadButtons">
						<span>
							{filesSection}
						</span>
					</div>
				</span>
			</div>
		</section>});
		
		return <div className='DownloadCenter__ListItem'>
			<h2 className='DownloadCenter__CategoryHeading'>
				{productSections.title}
			</h2>
			<div className='DownloadCenter__CategoryDesc'>
				{productSections.tagline}
			</div>

			<div className='DownloadCenter__CategoryItemsList'>
				{productSection}
				<div className='DownloadCenter__horizontalline'></div>
			</div>

		</div>
	})

	const metaTitle = "Terracotta Rainscreen - Sunscreen Features | Shildan Group";
	const metaDescription = "Find out how our terracotta rainscreens and sunscreens can improve your building's appearance. Download specifications and features for our materials.";
	const metaKeywords = "Terracotta rainscreen, terracotta sunscreen";

	return (
		<div>
			<SEO title={metaTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="download_center" navigation={prismicNavigation} footer={prismicFooter} />

			<section className="DownloadCenter">

				<div
					className={`DownloadCenter__Popup ${popupIsOpen === true ? 'open' : ''}`}
					ref={popupEl}>
					<div className="DownloadCenter__PopupClose" onClick={handlePopupClose}>
						<div className="DownloadCenter__PopupCloseLine DownloadCenter__PopupCloseLine--Left"></div>
						<div className="DownloadCenter__PopupCloseLine DownloadCenter__PopupCloseLine--Right"></div>
					</div>
					<iframe 
					src="https://forms.monday.com/forms/embed/2a04201785ba8abe42e97c9d3b1adadd?r=use1" 
					style={{ width: 650, height: 1000, border: 0, boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}
					></iframe>
				</div>

				<div className="DownloadCenter__Wrapper">
					<h1 className="DownloadCenter__Title">{pageTitle}</h1>

					<h2 className="DownloadCenter__Subtitle">{pageSubheading}</h2>

					<div className='DownloadCenter__List'>
						{productList}
					</div>

				</div>

			</section>

			<Footer footer={prismicFooter.data} hasBorder={false} />
		</div>
	)
}

export const query = graphql`
	query NewDownloadCenter {
		prismicNewDownloadCenter {
			data {
				title {
					text
				}
				subheading {
					text
				}
				product_group_list {
					name
					tagline
				}
				product_select_list {
					thumbnail {
						url
					}
					product_name
					product_group
					base_product
					orientation
					system
				}
				files {
					name {
						text
					}
					file {
						url
					}
					product
					product_group
					type
				}
				form_message_loading {
					text
				}
				form_message_success {
					text
				}
				form_message_error {
					text
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default DownloadCenter